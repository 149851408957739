@media (max-width: 576px) {
    .w-xs-50 {
        width: 50% !important;
    }
    .w-xs-100 {
        width: 100% !important;
    }
    .w-xs-80 {
        width: 80% !important;
    }
}

@media (min-width: 576px) {
    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    .w-md-50 {
        width: 50% !important;
    }
    .grid-md-franchises-selection__clz {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    .grid-md-franchises-selection-btn__clz {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
}

@media (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .franchises-font-size-xl__clz {
        font-size: 10px !important;
    }
    .mt-select__clz {
        margin-top: 8px !important;
    }
}

@media (min-width: 1200px) {
    .w-xl-40 {
        width: 40% !important;
    }
    .grid-xl-franchises-selection__clz {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-template-rows: repeat(1, 60px);
    }
    .grid-xl-franchises-selection-btn__clz {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        grid-template-rows: repeat(1, 36px);
    }
}

@media (min-width: 1601px) {
    .grid-gap-xxl__clz {
        gap: 250px !important ;
    }
}
